"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfrastructureSettings = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const storage_1 = require("./storage");
const queue_1 = require("./queue");
// Main InfrastructureSettings class
let InfrastructureSettings = class InfrastructureSettings {
    constructor(storageSettings, queueSettings) {
        this.storageSettings = storageSettings;
        this.queueSettings = queueSettings;
    }
};
exports.InfrastructureSettings = InfrastructureSettings;
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: storage_1.StorageSettings }),
    __metadata("design:type", storage_1.StorageSettings)
], InfrastructureSettings.prototype, "storageSettings", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: queue_1.QueueSettings }),
    __metadata("design:type", queue_1.QueueSettings)
], InfrastructureSettings.prototype, "queueSettings", void 0);
exports.InfrastructureSettings = InfrastructureSettings = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [storage_1.StorageSettings,
        queue_1.QueueSettings])
], InfrastructureSettings);
