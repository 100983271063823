"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedulerPayload = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
class SchedulerPayload {
    constructor(ft, m, i, iu, t, dt, d, r, nr, ni) {
        this.ft = ft;
        this.m = m;
        this.i = i;
        this.iu = iu;
        this.t = t;
        this.dt = dt;
        this.d = d;
        this.r = r;
        this.nr = nr;
        this.ni = ni;
    }
}
exports.SchedulerPayload = SchedulerPayload;
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'ft' }),
    __metadata("design:type", String)
], SchedulerPayload.prototype, "ft", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'm' }),
    __metadata("design:type", String)
], SchedulerPayload.prototype, "m", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'i' }),
    __metadata("design:type", Number)
], SchedulerPayload.prototype, "i", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'iu' }),
    __metadata("design:type", String)
], SchedulerPayload.prototype, "iu", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 't' }),
    __metadata("design:type", String)
], SchedulerPayload.prototype, "t", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'dt' }),
    __metadata("design:type", Array)
], SchedulerPayload.prototype, "dt", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'd' }),
    __metadata("design:type", Array)
], SchedulerPayload.prototype, "d", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'r' }),
    __metadata("design:type", Array)
], SchedulerPayload.prototype, "r", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'nr' }),
    __metadata("design:type", Number)
], SchedulerPayload.prototype, "nr", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'ni' }),
    __metadata("design:type", Number)
], SchedulerPayload.prototype, "ni", void 0);
