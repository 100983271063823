"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperatorTypeEnum = exports.OperatorLabels = exports.PgsqlOperators = exports.MongoOperators = void 0;
var MongoOperators;
(function (MongoOperators) {
    MongoOperators["EQUALS"] = "eq";
    MongoOperators["GREATER_THAN"] = "gt";
    MongoOperators["LESS_THAN"] = "lt";
    MongoOperators["NOT_EQUALS"] = "neq";
    MongoOperators["LESS_THAN_OR_EQUAL_TO"] = "lte";
    MongoOperators["GREATER_THAN_OR_EQUAL_TO"] = "gte";
    MongoOperators["CONTAINS"] = "contains";
    MongoOperators["IN"] = "in";
    MongoOperators["NOT_IN"] = "nin";
    MongoOperators["IS_BETWEEN"] = "isb";
    MongoOperators["IS_NOT_BETWEEN"] = "isnb";
    MongoOperators["STARTS_WITH"] = "startsWith";
    MongoOperators["ENDS_WITH"] = "endsWith";
    MongoOperators["DOES_NOT_START_WITH"] = "doesntStartsWith";
    MongoOperators["DOES_NOT_END_WITH"] = "doesntEndsWith";
})(MongoOperators || (exports.MongoOperators = MongoOperators = {}));
//pgsql operators
var PgsqlOperators;
(function (PgsqlOperators) {
    PgsqlOperators["EQUALS"] = "=";
    PgsqlOperators["GREATER_THAN"] = ">";
    PgsqlOperators["LESS_THAN"] = "<";
    PgsqlOperators["NOT_EQUALS"] = "<>";
    PgsqlOperators["LESS_THAN_OR_EQUAL_TO"] = "<=";
    PgsqlOperators["GREATER_THAN_OR_EQUAL_TO"] = ">=";
    PgsqlOperators["IN"] = "IN";
    PgsqlOperators["NOT_IN"] = "NOT IN";
    PgsqlOperators["IS_BETWEEN"] = "BETWEEN";
    PgsqlOperators["IS_NOT_BETWEEN"] = "NOT BETWEEN";
    PgsqlOperators["CONTAINS"] = "%LIKE%";
    PgsqlOperators["STARTS_WITH"] = "LIKE%";
    PgsqlOperators["ENDS_WITH"] = "%LIKE";
    PgsqlOperators["DOES_NOT_START_WITH"] = "NOT LIKE%";
    PgsqlOperators["DOES_NOT_END_WITH"] = "%NOT LIKE";
    PgsqlOperators["IS"] = "IS";
})(PgsqlOperators || (exports.PgsqlOperators = PgsqlOperators = {}));
//labels for operators
var OperatorLabels;
(function (OperatorLabels) {
    OperatorLabels["EQUALS"] = "Equals";
    OperatorLabels["GREATER_THAN"] = "Greater Than";
    OperatorLabels["LESS_THAN"] = "Less Than";
    OperatorLabels["NOT_EQUALS"] = "Not Equals";
    OperatorLabels["LESS_THAN_OR_EQUAL_TO"] = "Less Than or Equal to";
    OperatorLabels["GREATER_THAN_OR_EQUAL_TO"] = "Greater Than or Equal to";
    OperatorLabels["CONTAINS"] = "Contains";
    OperatorLabels["IN"] = "In ";
    OperatorLabels["NOT_IN"] = "Not in";
    OperatorLabels["IS_BETWEEN"] = "Is Between";
    OperatorLabels["IS_NOT_BETWEEN"] = "Is Not Between";
    OperatorLabels["STARTS_WITH"] = "Starts the String";
    OperatorLabels["ENDS_WITH"] = "Ends the String";
    OperatorLabels["DOES_NOT_START_WITH"] = "Does not start the String";
    OperatorLabels["DOES_NOT_END_WITH"] = "Does not end the String";
    OperatorLabels["IS"] = "Is";
})(OperatorLabels || (exports.OperatorLabels = OperatorLabels = {}));
//data types for specifying the kind of data being filtered or queried.
var OperatorTypeEnum;
(function (OperatorTypeEnum) {
    OperatorTypeEnum["NUMBER"] = "number";
    OperatorTypeEnum["STRING"] = "string";
    OperatorTypeEnum["BOOLEAN"] = "boolean";
    OperatorTypeEnum["CHARACTER_VARYING"] = "character varying";
    OperatorTypeEnum["VARCHAR"] = "varchar";
    OperatorTypeEnum["NUMERIC"] = "numeric";
    OperatorTypeEnum["INTEGER"] = "integer";
    OperatorTypeEnum["CHARACTER"] = "character";
    OperatorTypeEnum["DATE"] = "date";
})(OperatorTypeEnum || (exports.OperatorTypeEnum = OperatorTypeEnum = {}));
;
