import React, { useEffect, useState } from "react";
import { Col, Form,  Row,} from "antd";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import Selector from "../../../components/Selector/Selector";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getJourney,
  saveJourney,
  updateJourney,
} from "../../../helper/journeys";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import ShowMessage from "../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { getCookie } from "../../../helperFunctions/cookie";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { getEventList } from "../../../helper/metrices";

const JourneysCreate: React.FC = () => {
  const plzInputJourneyNameMsg = t("plzInputJourneyNameMsg");
  const plzInputJourneyDescriptionMsg = t("plzInputJourneyDescriptionMsg");
  const [loading, setLoading] = useState(false);
  const [journeyData, setJourneyData] = useState<any>({});
  const [eventData, setEventData] = useState({
    originalData: [],
    filteredData: [],
  });
  const [journeyId, setJourneyId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [journeyType, setJourneyType] = useState<"AGL" | "PAPL" | undefined>(undefined);
  const loginUser = useSelector((state: any) => state.loginUser);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const handleReset=()=>{
    setJourneyType(undefined)
    form.setFieldsValue({
      journeyName:undefined,
      journeyDescription:undefined,
      startEvent: undefined,
      endEvent: undefined,
      platform:undefined,
      period:undefined
    })
  }
  //fetches the event data
  async function fetchEventList() {
    try {
      const apiKey = loginUser.data.api_key;
      if (apiKey) {
        const payload = {
          appId,
          apiKey,
        };
        setLoading(true);
        const data  = await getEventList(payload)
        setEventData({ originalData: data, filteredData: data });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: error.message,
        show: true,
      });
    }
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  }
  useEffect(() => {
    if(journeyType){
      form.setFieldsValue({
        startEvent: undefined,
        endEvent: undefined,
      });
      setEventData((prevState: typeof eventData) => {
        const filtered_data = prevState?.originalData?.filter(
          ({ journey }) => journey == journeyType
          );
          return { ...prevState, filteredData: filtered_data };
        });
    }
    else{
      setEventData((prevState: typeof eventData)=>({...prevState,filteredData:prevState.originalData}))
    }
  }, [journeyType]);
  async function saveJourneyData() {
    try {
      const csrfToken = getCookie("_csrf");
      const payload = {
        data: journeyData,
        appId,
        apiKey: loginUser.data.api_key,
        csrfToken,
      };
      setLoading(true);
      const { message, status } = isEdit
        ? await updateJourney(payload)
        : await saveJourney(payload);
      setLoading(false);
      setShowMessage({
        type: status ? "success" : "error",
        message: message,
        show: true,
      });
    } catch (error: any) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: error.message,
        show: true,
      });
    }
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
       navigate("/dashboard/journeys");
    }, 2000);
  }

  //fetches the journey and updates loading state
  async function fetchJourneyData() {
    try {
      const apiKey = loginUser.data.api_key;
      if (apiKey) {
        const payload = {
          id: journeyId,
          appId,
          apiKey: loginUser.data.api_key,
        };
        setLoading(true);
        const data: any = await getJourney(payload);
        setJourneyData(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!location.state) {
      setIsEdit(false);
    } else {
      setIsEdit(true);

      const { id } = location.state;
      setJourneyId(id);
    }

    //if the page is edit
    if (journeyId) {
      const apiCalls = async () => {
        await fetchEventList();
        await fetchJourneyData();
      };
      apiCalls();
    }
    //if the page is create
    else if (!location.state) {
      fetchEventList();
    }
  }, [journeyId, loginUser]);


  const btnStyle = {
    width: "80px",
    marginRight: "5px",
    background: "var(--color-main-primary)",
    color: "--color-text-white",
  };
  const innerContainer = {
    backgroundColor: "var(--color-other-white)",
  };
  const [form] = Form.useForm();
  const onFormValueChange = (changedData: any) => {
    const keyName = Object.keys(changedData)[0];
    const value = Object.values(changedData)[0];
    setJourneyData({ ...journeyData, [keyName]: value });
  };
  return (
    <div className="container-fluid px-1">
      <Helmet>
        <title>Appice | Journeys | {isEdit ? "Edit" : "Create"}</title>
      </Helmet>
      {showMessage.show && (
        <ShowMessage type={showMessage.type} content={showMessage.message} />
      )}
      <CustomBreadCrumb
        items={[
          {
            title: <Link to="/dashboard/journeys">{t("journeysLabel")}</Link>,
          },
          {
            title: (
              <Link to="/dashboard/journeys/create">
                {isEdit ? t("editLabel") : t("createLabel")}
              </Link>
            ),
          },
        ]}
      />
      <div className="py-3">
        <h6 className="m-0">
          {isEdit ? t("editLabel") : t("createLabel")} Journey
        </h6>
      </div>
      <div className="container-fluid p-sm-4 p-3 " style={innerContainer}>
        {loading ? (
          <Spinner />
        ) : (
          <Form
            form={form}
            layout="vertical"
            initialValues={journeyData}
            onValuesChange={onFormValueChange}
            requiredMark={journeyData}
            onFinish={()=>saveJourneyData()}
          >
            <Row  className="d-flex justify-content-between">
              <Col span={11}>
               <Form.Item
               label={"Name"}
               name={"journeyName"}
               rules={[{ required: true, message: plzInputJourneyNameMsg }]}
              
                >
               <TextInput placeholder={t("journeyNameLabel")}  />
               </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={t("journeyLabel")}
                initialValue={journeyType}
                >
                  <Selector
                 allowClear
                 onClear={()=>setJourneyType(undefined)}
                  placeholder={`${t("selectLabel")}`}
                    onChange={(value) => setJourneyType(value)}
                    options={[
                      { label: "AGL", value: "AGL" },
                      { label: "PAPL", value: "PAPL" },
                    ]}
                  />
                </Form.Item>
              </Col>

            </Row>
            
            <Row className="d-flex justify-content-between">
              <Col span={24}>
                <Form.Item
                  label={"Description"}
                  name={"journeyDescription"}
                  rules={[
                    {
                      required: true,
                      message: plzInputJourneyDescriptionMsg,
                    },
                  ]}
                >
                  <TextInput placeholder={t("journeyDescriptionLabel")} />
                </Form.Item>
              </Col>
              
            </Row>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item label={t("startEventLabel")} name={"startEvent"}
              rules={[{ required: true, message: `${t("plzSelectStartEventMsg")}` }]}
                
                >
                  <Selector
                  placeholder={`${t("selectLabel")}`}
                    options={
                      eventData?.filteredData?.length > 0
                        ? [
                            { label: "All", value: "all" },
                            ...eventData.filteredData.map(
                              ({ event, displayname }) => ({
                                label: displayname,
                                value: event,
                              })
                            ),
                          ]
                        : [{ label: "All", value: "all" }]
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={t("endEventLabel")} name={"endEvent"}
              rules={[{ required: true, message: `${t("plzSelectEndEventMsg")}` }]}
                
                >
                  <Selector
                  placeholder={`${t("selectLabel")}`}
                    options={
                      eventData?.filteredData?.length > 0
                        ? [
                            { label: "All", value: "all" },
                            ...eventData.filteredData.map(
                              ({ event, displayname }) => ({
                                label: displayname,
                                value: event,
                              })
                            ),
                          ]
                        : [{ label: "All", value: "all" }]
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col span={11}>
                <Form.Item label={t("platformLabel")} name={"platform"}
              rules={[{ required: true, message: `${t("plzSelectPlatformMsg")}` }]}
                
                >
                  <Selector
                  placeholder={`${t("selectLabel")}`}
                    options={[
                      {
                        value: "all",
                        label: "All",
                      },
                      {
                        value: "android",
                        label: "Android",
                      },
                      {
                        value: "ios",
                        label: "IOS",
                      },
                      {
                        value: "web",
                        label: "Web",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={t("periodLabel")} name={"period"}
              rules={[{ required: true, message: `${t("plzSelectPeriodMsg")}` }]}  
                >
                  <Selector
                  placeholder={`${t("selectLabel")}`}
                    options={[
                      {
                        label: "Yesterday",
                        value: "yesterday",
                      },
                      {
                        label: "Last 7 days",
                        value: "last7days",
                      },
                      {
                        label: "Last 30 days",
                        value: "last30days",
                      },
                      {
                        label: "This Month",
                        value: "thismonth",
                      },
                      {
                        label: "Last Month",
                        value: "lastmonth",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <PrimaryButton type="primary" style={btnStyle}>
                  {t("previewLabel")}
                </PrimaryButton>
                <PrimaryButton
                  type="primary"
                  htmlType="submit"
                  style={btnStyle}
                >
                  {isEdit?t("updateLabel"):t("saveLabel")}
                </PrimaryButton>
                <PrimaryButton type="primary"  onClick={()=>handleReset()} style={btnStyle}>
                  {t("resetLabel")}
                </PrimaryButton>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default JourneysCreate;
