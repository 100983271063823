import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import SunburstChart from "../../../components/Charts/SunBurstChart/SunBurst";
import { Helmet } from "react-helmet";
import "./JourneysView.css";
import { Link, useParams } from "react-router-dom";
import centralApi from "../../../services/centralApi";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import CustomTable from "../../../components/CustomTable/CustomTable";
import CreateFunnelModal from "./CreateFunnelModal";
import Spinner from "../../../components/Spinner/Spinner";

const JourneysView: React.FC = () => {
  interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  const { id } = useParams();
  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);

  const api_key = loginUser.data.api_key;
  const [tableData, setTableData] = useState([]); // State variable to store table data
  const [modal, setModal] = useState(false);

  const [journeysChartData, setJourneysChartData] = useState<any>([]);
  const [isTableVisible, setTableVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  async function getJourney() {
    const payload = {
      app_id: appId,
      api_key: api_key,
      id: id,
    };
    const url: string = `/o/metrices/getJourney`;

    try {
      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      setJourneysChartData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const handleTableData = useCallback((data: any) => {
    // Process and use the data received from the SunburstChart
    setTableData(data);
  }, []);
  interface TableDataItem {
    name: string;
  }
  const eventNames: string[] = tableData.map(
    (item: TableDataItem | undefined) => item?.name || ""
  );

  // const eventNames = tableData.map((item: string) => item?.name);

  const openModal = useCallback(() => {
    setModal(true); // Toggles the modal state
  }, []);

  const columns = [
    {
      title: t("journeyLabelName"),
      dataIndex: "name",
      key: "name",
    },
    {
      title:  t("journeyLabelValue"),
      dataIndex: "value",
      key: "value",
    },
    {
      title:  t("journeyLabelPercentage"),
      dataIndex: "percentage",
      key: "percentage",
    },
  ];
  const handleShowTable = useCallback((isHovered: boolean) => {
    setTableVisible(isHovered); // Set to false when there are no hovered points
  }, []);

  useEffect(() => {
    getJourney();
  }, [id, api_key, appId]);
  return (
    <div className="container-fluid  p-sm-2 p-1">
      <Helmet>
        <title>Appice | Journeys</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3  flex-wrap">
        <div>
          <CustomBreadCrumb
            items={[
              {
                title: (
                  <Link to="/dashboard/journeys">{t("journeysLabel")}</Link>
                ),
              },
              {
                title: <Link to="">{t("journeysViewDataLabel")}</Link>,
              },
            ]}
          />
        </div>
      </div>

      <div className="px-sm-3 px-1 journey-view-container">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="acquisition-card-heading d-flex justify-content-between align-items-center  p-2">
              <strong>{journeysChartData?.journeyName}</strong>
            </div>

            <div className="px-sm-3 px-1" style={{ position: "relative" }}>
              <SunburstChart
                journeyData={journeysChartData?.paths}
                onTableData={handleTableData}
                openModal={openModal}
                showTable={handleShowTable}
                isTableVisible={isTableVisible}
                height = {500}
              />
              {/* <MyChart/> */}
              <div style={{ position: "absolute", top: "10px", right: "0px" }}>
                {isTableVisible && ( // Render the table only when isTableVisible is true
                  <CustomTable
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                  />
                )}
                <CreateFunnelModal
                  openModal={modal}
                  setModalOpen={setModal}
                  eventNames={eventNames}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JourneysView;
