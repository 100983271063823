import React, { useEffect } from "react";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MenuOutlined,
  StarTwoTone,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import homeIcon from "../../images/Home_svg.svg";
import activitiesIcon from "../../images/Analytics_svg.svg";
import usersIcon from "../../images/Audiences_svg.svg";
import churnIcon from "../../images/Churn1.svg";
import engagementIcon from "../../images/Models_svg.svg";
import dashboardIcon from "../../images/MLworkspace_svg.svg";
import documentationIcon from "../../images/Documents_svg.svg";
import acquisitionIcon from "../../images/Acquire4.svg";
import myappsIcon from "../../images/SetApp.svg";
import settingIcon from "../../images/Settings.svg";
import aiIcon from "../../images/ai.svg";
import "./PanelSideNav.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSideNavCollapsed } from "../../redux/features/layout/layoutInfoSlice";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice"; //calling features setting from redux
import { ROUTES } from "utils/constants";
import { checkUserRole } from "helperFunctions/common";
import { FeatureSettingsFlagTypes, roleCheckTypes } from "utils/constants/constants";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  { label, key, icon, children, type, routes }: {
    label: React.ReactNode;
    key: React.Key;
    icon?: React.ReactNode;
    children?: MenuItem[];
    type?: "group";
    routes?: string[];
  }
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    routes
  }as MenuItem;
}
const panelItems = (
  is_app_active: boolean,
  active_app_id: string,
  featuresSetting: any,
  userRole:any
  ) => {
  const imgStyle = { height: 20, width: 20 };

  const items: MenuItem[] = [
    getItem({
      label: <Link to={ROUTES.DASHBOARD}>Home</Link>,
      key: ROUTES.DASHBOARD,
      icon: <img style={imgStyle} src={homeIcon} alt="home icon" />
    }),

    is_app_active
      ? getItem({
          label: <Link to={`${ROUTES.DASHBOARD_APP}?id=${active_app_id}`}>Dashboard</Link>,
          key: ROUTES.DASHBOARD_APP,
          routes: [ROUTES.DASHBOARD_APP,ROUTES.DASHBOARD_APPUSER, ROUTES.DASHBOARD_APPUSER_DETAIL],

          icon: <img style={imgStyle} src={dashboardIcon} alt="dashboard icon" />
        })
      : null,

    checkUserRole(userRole, roleCheckTypes.SuperUser)
      ? getItem({
          label: "Setup Apps",
          key: "Setup Apps",
          icon: <img style={imgStyle} src={myappsIcon} alt="apps icon" />,
          children: [
            getItem({
              label: "Setup New App",
              key: "Setup New App"
            }),
            getItem({
              label: "Setup New Chatbot",
              key: "Setup New Chatbot"
            })
          ]
        })
      : null,

    is_app_active
      ? getItem({
          label: "Users",
          key: "Users",
          icon: <img style={imgStyle} src={usersIcon} alt="users icon" />,
          children: [
            getItem({
              label: <Link to={ROUTES.DASHBOARD_CUSTOMER}>Customer 360</Link>,
              key: ROUTES.DASHBOARD_CUSTOMER,
              routes: [ROUTES.DASHBOARD_CUSTOMER,ROUTES.DASHBOARD_CUSTOMER_DETAIL],


            }),
            (checkUserRole(userRole, roleCheckTypes.Contributor)) ?
            getItem({
              label: <Link to={ROUTES.DASHBOARD_AUDIENCE}>Audience Segments</Link>,
              key: ROUTES.DASHBOARD_AUDIENCE,
              routes: [ROUTES.DASHBOARD_AUDIENCE, ROUTES.DASHBOARD_AUDIENCE_CREATE]
            }):null ,
            featuresSetting?.[FeatureSettingsFlagTypes.TRAITS] === false  && (checkUserRole(userRole, roleCheckTypes.Contributor)) 
              ? getItem({
                  label: <Link to={ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS}>Traits</Link>,
                  key: ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS,
                  routes: [ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE,ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS],

                })
              : null,
            featuresSetting?.[FeatureSettingsFlagTypes.DEMOGRAPHICS] === false

              ? getItem({
                  label: <Link to={ROUTES.DASHBOARD_DEMOGRAPHICS}>Demographics</Link>,
                  key: ROUTES.DASHBOARD_DEMOGRAPHICS
                })
              : null
          ]
        })
      : null,

    is_app_active
      ? getItem({
          label: "Acquisition",
          key: "Acquisition",
          icon: <img style={imgStyle} src={acquisitionIcon} alt="acquisition icon" />,
          children: [
            getItem({
              label: <Link to={ROUTES.DASHBOARD_ACQUISITION}>App Acquisition</Link>,
              key: ROUTES.DASHBOARD_ACQUISITION
            })
          ]
  })
      : null,
    is_app_active
      ? getItem({
          label: "Analytics",
          key: "Analytics",
          icon: <img style={imgStyle} src={activitiesIcon} alt="activities icon" />,
          children: [
            featuresSetting?.[FeatureSettingsFlagTypes.USAGE] === false
              ? getItem({
                  label: <Link to={ROUTES.DASHBOARD_USAGE}>Usage</Link>,
                  key: ROUTES.DASHBOARD_USAGE
                })
              : null,
            getItem({
              label: <Link to={ROUTES.DASHBOARD_EVENTS}>Events</Link>,
              key: ROUTES.DASHBOARD_EVENTS
            }),
            (checkUserRole(userRole, roleCheckTypes.Contributor)) ?
            getItem({
              label: <Link to={ROUTES.DASHBOARD_FUNNELS}>Funnels</Link>,
              key: ROUTES.DASHBOARD_FUNNELS,
              routes: [ROUTES.DASHBOARD_FUNNELS,ROUTES.DASHBOARD_FUNNELS_CREATE, ROUTES.DASHBOARD_FUNNEL_DETAIL],


            }):null ,
            getItem({
              label: <Link to={ROUTES.DASHBOARD_JOURNEYS}>Journeys</Link>,
              key: ROUTES.DASHBOARD_JOURNEYS,
              routes: [ROUTES.DASHBOARD_JOURNEYS_CREATE,ROUTES.DASHBOARD_JOURNEYS_VIEW, ROUTES.DASHBOARD_JOURNEY_MANAGEMENT],

            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_TRENDS_ALLTRENDS}>Insights</Link>,
              key: ROUTES.DASHBOARD_TRENDS_ALLTRENDS
            })
          ]
        })
      : null,

    is_app_active
      ? getItem({
          label: "Engagement",
          key: "Engagement",
          icon: <img style={imgStyle} src={engagementIcon} alt="engagement icon" />,
          children: [
            (checkUserRole(userRole, roleCheckTypes.Contributor)) || (checkUserRole(userRole, roleCheckTypes.Reviewer)) ?
            getItem({
              label: <Link to={ROUTES.DASHBOARD_CAMPAIGN}>Campaigns</Link>,
              key: ROUTES.DASHBOARD_CAMPAIGN,
              routes: [ROUTES.DASHBOARD_CAMPAIGN,ROUTES.DASHBOARD_CAMPAIGN_CREATE, ROUTES.DASHBOARD_CAMPAIGN_DETAIL],

            }):null ,
            featuresSetting?.[FeatureSettingsFlagTypes.TRANSACTIONAL_CAMP] === false

              ? getItem({
                  label: <Link to={ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN}>Transactional Campaigns</Link>,
                  key: ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN,

                })
              : null,
              featuresSetting?.[FeatureSettingsFlagTypes.PERFORMANCE_REPORTS] === false

              ?
            getItem({
              label:<Link to={ROUTES.DASHBOARD_CAMPAIGN_WEBPUSHREPORTS}>
                Reports
              </Link>,
             key: ROUTES.DASHBOARD_CAMPAIGN_WEBPUSHREPORTS
      }) 
            : null,
            getItem({
              label:<Link to={ROUTES.DASHBOARD_EXPORTS}>Funnel Reports</Link>,
            key:  ROUTES.DASHBOARD_EXPORTS
      }),
      (checkUserRole(userRole, roleCheckTypes.Contributor)) ?
            getItem({
            label:  <Link to={ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE}>Template </Link>,
              key:ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE
       } ):null,
            getItem({

            
             label: <Link to={ROUTES.DASHBOARD_UTMBUILDER}>UTM Builder</Link>,
            key:  ROUTES.DASHBOARD_UTMBUILDER
        }  ),
          ]
  })
      : null,
    is_app_active
      ? 
      featuresSetting?.[FeatureSettingsFlagTypes.XMIT_AI] === false

        ? getItem({
            label: <Link to={ROUTES.GENAI_DASHBOARD}>Xmit AI</Link>,
            key: ROUTES.GENAI_DASHBOARD,
            icon: <img style={imgStyle} src={aiIcon} alt="ai icon" />
          })
        : null
      : null,

    is_app_active
      ? 
      featuresSetting?.[FeatureSettingsFlagTypes.CHURN] === false

        ? getItem({
            label: <Link to={ROUTES.DASHBOARD_CHURN}>Churn</Link>,
            key: ROUTES.DASHBOARD_CHURN,
            icon: <img style={imgStyle} src={churnIcon} alt="churn icon" />
          })
        : null
      : null,

    featuresSetting?.[FeatureSettingsFlagTypes.LOYALTY] === false

      ? getItem({
          label: "Loyalty",
          key: "Loyalty",
          icon: <StarTwoTone style={{ fontSize: 20 }} />,
          children: [
            getItem({
              label: <Link to={ROUTES.DASHBOARD_LOYALTY}>Dashboard</Link>,
              key: ROUTES.DASHBOARD_LOYALTY
            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_LOYALTY_MEMBERS}>Members</Link>,
              key: ROUTES.DASHBOARD_LOYALTY_MEMBERS
            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_LOYALTY_SEGMENTS}>Segments</Link>,
              key: ROUTES.DASHBOARD_LOYALTY_SEGMENTS
            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_LOYALTY_TIER}>Tier</Link>,
              key: ROUTES.DASHBOARD_LOYALTY_TIER
            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_LOYALTY_REWARDS}>Rewards</Link>,
              key: ROUTES.DASHBOARD_LOYALTY_REWARDS
            })
          ]
        })
      : null,

    is_app_active
      ? getItem({
          label: "Data Management",
          key: "Data Management",
          icon: <img style={imgStyle} src={churnIcon} alt="icon" />,
          children: [
            getItem({
              label: <Link to={ROUTES.DASHBOARD_DATA_TRANSFORMATION}>Customer 360 </Link>,
              key: ROUTES.DASHBOARD_DATA_TRANSFORMATION
            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_JOURNEY_MANAGEMENT}>Journeys</Link>,
              key: ROUTES.DASHBOARD_JOURNEY_MANAGEMENT
            }),
            getItem({
              label: <Link to={ROUTES.DASHBOARD_MANAGEMENT_PERSONALISATION}>Personalisation </Link>,
              key: ROUTES.DASHBOARD_MANAGEMENT_PERSONALISATION
            })
          ]
        })
      : null
  ];


  return items;

};
type propTypes = {
  isStory?: boolean;
};
const PanelSideNav: React.FC<propTypes> = ({ isStory }) => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const activeApp = useSelector((state: any) => state.activeApp);
  /**
 * This function checks the `pathname` against the `key` and `routes` of each item
 * in the provided `items` array. It recursively checks the children of the items if they exist.
 * The function returns the `key` of the item that matches the `pathname`.
 *
 * @param {string} pathname - The current path from `location.pathname` (the URL).
 * @param {Array} items - The menu items to check against the `pathname`. These items can have child items too.
 * @returns {string | null} - The selected key that matches the `pathname` or `null` if no match is found.
 */

const getSelectedKey = (pathname: string, items: any): string | null => {
  let selectedKey = null;

  items.forEach((item: any) => {
    // Check if the route contains a dynamic segment (like campaign ID)
    if (Array.isArray(item?.routes)) {
      // Check if any route pattern matches the pathname
      item.routes.forEach((route: string) => {
        const dynamicPattern = /:id/; // check this  based on your dynamic route then hightlight its parent
        if (dynamicPattern.test(route)) {
          // Handle dynamic route (e.g., campaign ID)
          const routeBase = route.split('/:')[0];
          if (pathname.startsWith(routeBase)) {
            selectedKey = item?.key;  // Set the selected key if the base part matches
          }
        } else if (pathname === route) {
          selectedKey = item?.key;
        }
      });
    } else {
      // If it's not an array, check if the item key matches the current pathname
      if (pathname === item?.key) {
        selectedKey = item?.key;
      }
    }

    // If the item has children, recurse into them to find the selected key
    if (item?.children) {
      const childKey = getSelectedKey(pathname, item.children);
      if (childKey) {
        selectedKey = childKey;
      }
    }
  });

  return selectedKey;
};

  const userRole = loginUser?.activeAppUserRole
  const { sideNavCollapsed, contentDimensions } = useSelector(
    (state: any) => state.layoutInfo
  );
  const dispatch: any = useDispatch();
  const collapsedScreenWidth = 580;
  const toggleCollapsed = () => {
    dispatch(setSideNavCollapsed(!sideNavCollapsed));
  };
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  //calling fetures setting
  useEffect(() => {
    if (activeApp.appId && loginUser.api_key) {
      dispatch(
        getFeaturesSettings({
          api_key: loginUser.api_key,
          app_id: activeApp?.appId,
        })
      );
    }
  }, [activeApp, loginUser]);
 // Generate the menu items dynamically based on the app's active state, features, and user role
const menuItems = panelItems(
  activeApp.appId && activeApp.appName ? true : false, // Check if active app is valid
  activeApp.appId, // Pass appId to menuItems function
  featuresSetting, // Pass featuresSetting to manage feature-based display
  userRole // Pass userRole to manage role-based visibility of menu items
);

// Get the selected key for highlighting the correct menu item based on the current URL
// `location.pathname` is the current path in the browser's URL
// `menuItems` contains the dynamically generated menu items with keys and potential children


const bottomMenuItems = (
  activeApp: any,
  appId: string | undefined,
  featuresSetting: any, // Assuming 'featuresSetting' is an object
  userRole: any // Assuming 'userRole' is a string representing the user's role
) => {

  return [
      // Checking if appId and appName are available
      activeApp?.appId && activeApp?.appName
        ? getItem({
            label: "Settings",
            key: "Settings", 
            icon: <img src={settingIcon} alt="settings icon" />, // Adding icon
            children: [
              featuresSetting?.[FeatureSettingsFlagTypes.MANAGE_MODULES] === false?

           getItem({ label: <Link to={"#"}>Manage Modules</Link>, key: "sub200" }):null,
              (checkUserRole(userRole, roleCheckTypes.UserAdminRights )) ? getItem({
                label: <Link to={ROUTES.DASHBOARD_ALLUSERS}>User Management</Link>,
                key: ROUTES.DASHBOARD_ALLUSERS
              }) : null,
              (checkUserRole(userRole, roleCheckTypes.SuperUser)) ? getItem({
                label: <Link to={ROUTES.DASHBOARD_APPSETTING}>App Setting</Link>,
                key: ROUTES.DASHBOARD_APPSETTING
              }) : null,
              (checkUserRole(userRole, roleCheckTypes.Infosec)) ? getItem({
                label: <Link to={ROUTES.DASHBOARD_AUDIT_LOGS}>Audit Logs</Link>,
                key: ROUTES.DASHBOARD_AUDIT_LOGS
              }) : null,
              featuresSetting?.[FeatureSettingsFlagTypes.DISPLAY_PREFERENCE] === false?

              getItem({
                label: <Link to={ROUTES.DASHBOARD_DISPLAY_PREFRENCE}>Display Preference</Link>,
                key: ROUTES.DASHBOARD_DISPLAY_PREFRENCE
              }):null
            ]
          })
        : null,
    
      // Checking if documentation setting is false to hide documentation menu
      featuresSetting?.[FeatureSettingsFlagTypes.DOCUMENTATION] === false?

        getItem({
            label: "Documentation",
            key: "Documentation", 
            icon: <img src={documentationIcon} alt="documentation icon" />,
            children: [getItem({ label: "IOS Integration", key: "IOS Integration" })]
          })
        : null,
    
    ].filter((item) => item !== null); // Filter out null items
};

const bottomMenu = bottomMenuItems(
  activeApp, // Check if active app is valid
  activeApp.appId, // Pass appId to menuItems function
  featuresSetting, // Pass featuresSetting to manage feature-based display
  userRole // Pass userRole to manage role-based visibility of menu items
);

// For the top menu (panelItems) - use exact match
const selectedKey:any = getSelectedKey(location.pathname, menuItems);

// For the bottom menu (bottomMenu) - use exact match
const selectedBottomKey:any = getSelectedKey(location.pathname, bottomMenu);

  return (
    // isStory will be true if the component is rendered through stroybook
    (loginUser.api_key || isStory) && (
      <Sider
        width={230}
        collapsed={
          contentDimensions.width > collapsedScreenWidth
            ? sideNavCollapsed
            : true
        }
        theme="light"
        className="panel-sidenav position-relative overflow-scroll"
      >
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[selectedKey]} // Dynamically update selected item

          defaultOpenKeys={[location.pathname]}
          theme="light"
          mode={
            contentDimensions.width > collapsedScreenWidth
              ? "inline"
              : "horizontal"
          }
          overflowedIndicator={<MenuOutlined style={{ fontSize: 18 }} />}
          //calling function panelItems
          items={panelItems(
            activeApp.appId && activeApp.appName ? true : false,
            activeApp.appId,
            featuresSetting,
            userRole,
          )}
        ></Menu>
        :{/* bottom menu */}
        <div style={{ position: "sticky", top: 560, minHeight: 120 }}>
          <Menu
            selectedKeys={[selectedBottomKey]} // Dynamically update selected item
            defaultSelectedKeys={[location.pathname]}
            defaultOpenKeys={[location.pathname]}
            theme="light"
            mode={
              contentDimensions.width > collapsedScreenWidth
                ? "inline"
                : "horizontal"
            }
            overflowedIndicator={<MenuOutlined style={{ fontSize: 18 }} />}
           
            items={bottomMenuItems(
              activeApp,
              activeApp.appId,
              featuresSetting,
              userRole,
            )}
          ></Menu>
          {contentDimensions.width > collapsedScreenWidth && (
            <span className="collapser-span" onClick={toggleCollapsed}>
              {sideNavCollapsed ? (
                <DoubleRightOutlined />
              ) : (
                <DoubleLeftOutlined />
              )}
            </span>
          )}
        </div>
      </Sider>
    )
  );
};

export default PanelSideNav;
