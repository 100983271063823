import { Divider } from "antd";
import "./Features.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import React, { useState, useEffect } from "react";
import centralApi from "../../../../services/centralApi";
import Spinner from "../../../../components/Spinner/Spinner";
import NoData from "../../../../components/NoData/NoData";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {setFeatureSettings } from "redux/features/app/featuresSettingSlice";
import useMessage from "hooks/useMessage";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { getToken } from "redux/features/app/authTokenSlice";

interface FeatureData {
  [key: string]: boolean;
}

const Features: React.FC = () => {
  const dispatch: any = useDispatch();
  const featureData =  useSelector((state: any) => state.featuresSetting?.data[0]?.features || {});
  const { showError, showSuccess } = useMessage();  
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const appId = activeApp?.appId;
  const api_key = loginUser?.data.api_key;

  //Labels for feature settings
  const labels: { [key: string]: string } = {
    demographics: t("demographicsLabel"),
    language_variant: t("languageVariantLabel"),
    ab_testing_variant: t("abTestingVariantLabel"),
    usage: t("usageLabel"),
    reports: t("reportsLabel"),
    appInboxReports: t("appInboxReportsLabel"),
    maskedDids: t("maskedDidsLabel"),
    churn: t("churnLabel"),
    expImg: t("expImgLabel"),
    advPush: t("advPushLabel"),
    campExceptPush: t("campExceptPushLabel"),
    transactionalCamp: t("transactionalCampLabel"),
    performanceReports: t("performanceReportsLabel"),
    userprofileImg: t("userprofileImgLabel"),
    optimisation: t("optimisationLabel"),
    appInboxAndDeliveryType: t("appInboxAndDeliveryTypeLabel"),
    funnelPeriod: t("funnelPeriodLabel"),
    documentation: t("documentationLabel"),
    outsideTheApp: t("outsideTheAppLabel"),
    campaignDetailsCsv: t("campaignDetailsCsvLabel"),
    competingApps: t("competingAppsLabel"),
    hidegranularMonetaryCACLTV: t("hidegranularMonetaryCACLTVLabel"),
    embedCustomField: t("embedCustomFieldLabel"),
    whowhatwherewhen: t("whowhatwherewhenLabel"),
    deepLinkUrl: t("deepLinkUrlLabel"),
    category: t("categoryLabel"),
    utmBuilder: t("utmBuilderLabel"),
    email_editor: t("emailEditorLabel"),
    landingPageView: t("landingPageViewLabel"),
    funnelLaunchbtn: t("funnelLaunchbtnLabel"),
    cmpInsertInToPG: t("cmpInsertInToPGLabel"),
    advancedFreq: t("advancedFreqLabel"),
    launchCampFreq: t("launchCampFreqLabel"),
    audience_exports: t("audienceExportsLabel"),
    actionbuttonSettings: t("actionButtonSettingsLabel"),
    specialattributes: t("specialAttributesLabel"),
    conversionSettings: t("conversionSettingsLabel"),
    journeynavsettings: t("journeyNavSettingsLabel"),
    setupAppsSettings: t("setupAppsSettingsLabel"),
    delayLauch: t("delayLaunchLabel"),
    removeWeb: t("removeWebLabel"),
    liveEventSett: t("liveEventSettLabel"),
    schedulerSett: t("schedulerSettLabel"),
    cSegPhoneEmail: t("phone/emailLabel"),
    xmitAi: t("xmitAiLabel"),
    loyalty: t("loyaltyLabel"),
    deviceAttributes:t("deviceAttributesLabel"),
    appUsageAttributes : t("appUsageAttributesLabel"),
    traits:t("traitsLabel"),
    models:t("modelsLabel"),
    audienceSync:t("audienceSyncLabel"),
    allowList : t("allowListLabel"),
    accountEngagement : t("accountEngagementLabel"),
    digitalEngagement : t("digitalEngagemnetLabel"),
    transactionalData : t("transactionalDataLabel"),
    downloadSDK:t("downloadSdkLabel"),
    manageModules:t("manageModulesLabel"),
    displayPreference:t("displayPreferenceLabel")


  };


  const handleChange = (featureKey: string, newValue: boolean) => {
    dispatch(setFeatureSettings({[featureKey]: newValue}))
  };

  //map data to thier respective columns
  const getAllFeatures = () => {
    const labelToKeyMapping: { [label: string]: string } = {
      demographicsState: "demographics",
      usageState: "usage",
      reportsState: "reports",
      appInboxReports: "appInboxReports",
      maskedDids: "maskedDids",
      churnState: "churn",
      expImgState: "expImg",
      advPushState: "advPush",
      campExceptPushState: "campExceptPush",
      transState: "transactionalCamp",
      performanceReports:'performanceReports',
      userprofileImgState: "userprofileImg",
      hidegranularMonetaryCACLTV: "hidegranularMonetaryCACLTV",
      optimisationState: "optimisation",
      appInboxDeliveryState: "appInboxAndDeliveryType",
      funnelPeriod: "funnelPeriod",
      documentationState: "documentation",
      outsideTheAppState: "outsideTheApp",
      campaignDetailsCsv: "campaignDetailsCsv",
      competingApps: "competingApps",
      embedCustomField: "embedCustomField",
      whowhatwherewhen: "whowhatwherewhen",
      audience_exports: "audience_exports",
      deepLinkUrl: "deepLinkUrl",
      category: "category",
      utmBuilder: "utmBuilder",
      email_editor: "email_editor",
      landingPageView: "landingPageView",
      funnelLaunchbtn: "funnelLaunchbtn",
      language_variant: "language_variant",
      ab_testing_variant: "ab_testing_variant",
      cmpInsertInToPG: "cmpInsertInToPG",
      advancedFreq: "advancedFreq",
      launchCampFreq: "launchCampFreq",
      actionbuttonSettings: "actionbuttonSettings",
      specialattributes: "specialattributes",
      conversionSettings: "conversionSettings",
      journeynavsettings: "journeynavsettings",
      setupAppsSettings: "setupAppsSettings",
      delayLauch: "delayLauch",
      removeWeb: "removeWeb",
      liveEventSett: "liveEventSett",
      schedulerSett: "schedulerSett",
      cSegPhoneEmail: "cSegPhoneEmail",
      xmitAi: "xmitAi",
      loyalty: "loyalty",
      deviceAttributes: "deviceAttributes",
      appUsageAttributes: "appUsageAttributes",
      models:"models",
      traits:"traits",
      audienceSync:"audienceSync",
      allowList : "allowList",
      accountEngagement : "accountEngagement",
      digitalEngagement : "digitalEngagement",
      transactionalData : "transactionalData",
      downloadSDK:"downloadSDK",
      manageModules:"manageModules",
      displayPreference:"displayPreference"


    };
    const reversedFeatureData: Record<string, boolean> = {};

    for (const label in labelToKeyMapping) {
      if (Object.prototype.hasOwnProperty.call(labelToKeyMapping, label)) {
        const key = labelToKeyMapping[label];
        reversedFeatureData[label] = featureData[key] || BOOLEAN_OPTIONS.FALSE;
      }
    }

    return reversedFeatureData;
  };

  const handleSubmit = async () => {
    const selectedFeatures = getAllFeatures();
    const payload = {
      args: JSON.stringify({
        ...selectedFeatures,
        app_id: appId,
      }),
      app_id: appId,
      authToken,
      api_key: api_key,
    };
    await saveFeatureSetting(payload);
  };

  async function saveFeatureSetting(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_FEATURES_SETTINGS_URL;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
    dispatch(getToken())
  }

  useEffect(() => {
    // Check if featureData is empty or null
    if (Object.keys(featureData).length === 0) {
      setShowNoDataMessage(true);
    } else {
      setShowNoDataMessage(false);
    }
  }, [featureData]);

  return (
    <div className="feature-setting-container">
      <div className="mt-4 mb-4">
        <h6 className="feature-setting-container-heading">{t("featuresSettingHeadingLabel")}</h6>
        <Divider className="feature-setting-custom-divider" />
      </div>

      <div className="mt-3">
        <p>
         {t("featuresSettingDescriptionLabel")}
        </p>
        <div className="mt-3 align-items-center feature-setting-form">
          {showNoDataMessage ? (
            <NoData />
          ) : loading ? (
            <Spinner />
          ) : (
            <Form onFinish={handleSubmit}>
              {Object.keys(labels).map((featureKey) => (
                <Form.Item
                  key={featureKey}
                  name={featureKey}
                  initialValue={featureData[featureKey]}
                >
                  <CustomCheckbox
                    label={labels[featureKey]}
                    checked={featureData[featureKey]}
                    onChange={(e: any) =>
                      handleChange(featureKey, e.target.checked)
                    }
                  />
                </Form.Item>
              ))}
              <Form.Item>
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "70px" }}
                >
                  {t("saveLabel")}
                </PrimaryButton>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Features;
