import React, { useEffect,  useState } from "react";
import { Form } from "antd";
import TextInput from "components/Inputs/TextInput/TextInput";
import "./SignUp.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import {
  validateConfirmPassword,
  validateEmail,
  validateLetters,
  validatePassword,
} from "../../../validator/antdValidator";
import { useTranslation } from "react-i18next";
import Spinner from "components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import useMessage from "../../../hooks/useMessage";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import centralApi from "services/centralApi";
import { processString } from "helperFunctions/string";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { adAuthTypeEnum, EDStatusEnum, SSOTypeEnum, userType } from "utils/enums";
import { useDispatch } from "react-redux";
import { fetchLoginUser } from "redux/features/user/loginUserSlice";
import { useForm } from "antd/es/form/Form";
import { CONSTANTS } from "utils/constants/constants";
const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useMessage();
  const [isUserIdVerified, setUserVerify] = useState(false)
  const [isNameFieldsHide,setNameFieldsHide]  = useState(false);
  const [isPasswordFieldsHide,setPasswordFieldsHide]  = useState(false);
  const [ssoState ,setSsoState] = useState('')
  const [isAdState, setIsAdState] = useState(false);
  const [showSso, setShowSso] = useState(true); // true for SSO, false for Email
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [signUpPageInfo, setSignUpPageInfo] = useState({csrfToken:"",captchaSvg:"",siteSettings:{captcha:""},portal_name:""});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [form] = useForm();

  // This function will reset the form values on the signup page 
  const resetFormValues = () => {
    setFormValues({}); 
    form.resetFields()
  };
  // This function will handle the toggle between SSO and Normal signup
  const handleToggleSignupMethod = () => {

    setShowSso(!showSso); 
    setUserVerify(false)
    setIsEmailDisabled(false);
    resetFormValues();             
    // Update labels dynamically based on ssoState
    if (showSso) {
      if (ssoState === adAuthTypeEnum.AD) {
        setIsAdState(true);
        setNameFieldsHide(true)
        setPasswordFieldsHide(true)
  
  
      }else{
        // TODO SAML FOR LATER
      }
    } else {
      setIsAdState(false)
    }
  };
  
  //handle form submit
  const sendDataToApi = async ({
    first_name,
    last_name,
    signup_email,
    signup_password = CONSTANTS.DEFAULTKEY, // Default value
    _csrf,
    captcha
  }: {
    first_name: string;
    last_name: string;
    signup_email: string;
    signup_password: string;
    _csrf: string;
    captcha:string;
  }) => {
    // Prepare the payload with encoded and processed data
    const payload = {
      full_name: btoa(first_name + last_name),
      hemail: btoa(signup_email),
      password: processString(signup_password),
      selectedButton: SSOTypeEnum.LOGIN_EMAIL,
      _csrf,
      captcha
    };
    // delete data.confirmPassword;
    try {
      // Send a POST request with the payload to the signup URL
      const res = await centralApi(
        "POST",
        API_ENDPOINTS.SIGNUP_URL,
        payload,
        null
      );
      setSignUpPageInfo(res)
      // Check if the response contains a redirect
      if (res.redirect) {
        // Navigate to the redirect path
        navigate(res.redirectPath, { replace: true });
      } else {
        showSuccess(res.message);
      }
    } catch (error: any) {
      setIsCaptchaVisible(false)
      setSignUpPageInfo(error?.response?.data || {})
      showError(error?.response?.data?.message || t("somethingWrongLabel"));
    }
    form.resetFields(["captcha"])
  };
  // This function will verify whether the signup email exist or not,
  const verifyUserId = async (email: string) => {
    try {

      // create payload
      const payload = {
        args: JSON.stringify({
          email: email,
          selectedButton: SSOTypeEnum.LOGIN_EMAIL
        }),
      }

      // call the api
      const res = await centralApi("GET", API_ENDPOINTS.GET_EMAIL_EXISTENCE , null, payload);

      // if user doesn't exit and he is allowed to continue in signup process
      if(!res.exist && res.allowUser){
        setUserVerify(true)
        setNameFieldsHide(false)    // Show first name and last name options to him
  
        //Disable the email of the user
        setIsEmailDisabled(true);
  
        if (showSso) {
          setPasswordFieldsHide(false)    // show passwords if it is not the case of sso
  
        }  
      }else{
        // otherwise show, Email or Adid Exist
        showError(` ${ showSso ? userType.EMAIL : userType.AD }  ${t("already exists")}`); // Handle errors
      }

    } catch (error: any) {
      showError(error.message || t("somethingWrongLabel")); // Handle errors
    }
  };
  
  //fetch signup info
  const fetchSignUp = async () => {
    try {
      setLoading(true);
      const res = await centralApi("GET", API_ENDPOINTS.GET_SIGNUP_URL);
      // Check if the response contains a redirect
      if (res.redirect) {
        // If the redirect path is the dashboard, fetch login user
        if (res.redirectPath == ROUTES.DASHBOARD) {
          dispatch(fetchLoginUser());
        }
        // navigate to the redirect path
        navigate(res.redirectPath, { replace: true });
      }
      setSignUpPageInfo(res)
      setNameFieldsHide(true)
      setPasswordFieldsHide(true)

      // set the sso state
      if(res.siteSettings && res.siteSettings.sso_type){
         setSsoState(res.siteSettings.sso_type)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  //handle input change
  const handleFieldChange=(data:any)=>{
    setFormValues((prevState:any)=>({...prevState,...data}))
  }
  //handle form submit
  const handleSubmit = async () => {
    if(isUserIdVerified){
    const captchaStatus=signUpPageInfo.siteSettings?.captcha
    if (isCaptchaVisible || captchaStatus==EDStatusEnum.DISABLE || !captchaStatus) {
        sendDataToApi({...formValues,_csrf:signUpPageInfo.csrfToken});
      }
     else {
      setIsCaptchaVisible(true);
    }
  }else{
    const { signup_email } = formValues;
      verifyUserId(signup_email)
  }
  };
  useEffect(() => {
    fetchSignUp();
  }, []);
  return (
    <div className="container-fluid p-0 signup-container d-flex justify-content-center align-items-center  h-100">
      <Helmet>
        <title>Appice | SignUp</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : (
        <div className=" p-5 signup-form-wrapper">
          <h4
            className="pb-2 text-center"
            style={{ fontWeight: "var(--font-weight-heading-strong)" }}
          >
            {t("signUpTitleWithoutName")}{" "}
            {signUpPageInfo?.portal_name
              ? signUpPageInfo?.portal_name
              : t("companyNameLabel")}
          </h4>

          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{ _csrf: signUpPageInfo.csrfToken }}
            validateTrigger={["onBlur"]}
          >
            {!isCaptchaVisible ? (
              <>
                {" "}
               { isNameFieldsHide ? <></> : 
                <div className="d-flex flex-wrap  justify-content-between">
                  <Form.Item
                    label={t("firstNameLabel")}
                    name="first_name"
                    className="col-12 pe-1  col-sm-6"
                    rules={[
                      { validator: validateLetters },
                      { required: true, message: `${t("emptyFirstNameMsg")}` },
                    ]}
                  >
                    <TextInput onChange={(e:any)=>handleFieldChange({first_name:e.target.value})} placeholder={t("firstNameLabel")} />
                  </Form.Item>
                  <Form.Item
                    label={t("lastNameLabel")}
                    name="last_name"
                    className="col-12 ps-1 col-sm-6"
                    rules={[
                      { validator: validateLetters },
                      { required: true, message: `${t("emptyLastNameMsg")}` },
                    ]}
                  >
                    <TextInput onChange={(e:any)=>handleFieldChange({last_name:e.target.value})} placeholder={t("lastNameLabel")} />
                  </Form.Item>
                </div>
                }
                <Form.Item
                  label={isAdState ? t("adidLabel") : t("emailLabel")}
                  name="signup_email"
                  rules={[
                    { required: true, message: `${t("emptyEmailMsg")}` },
                    ...(isAdState ? [] : [{ validator: validateEmail }]), 
                  ]}
                >
                  <TextInput onChange={(e:any)=>handleFieldChange({signup_email:e.target.value })} placeholder={isAdState ? t("adidLabel") : t("emailLabel")}    
                   disabled={isEmailDisabled} 
                   value={formValues.signup_email || ""} 
                    />
                </Form.Item>
                { isPasswordFieldsHide ? <></> : 
                 <>
                <Form.Item
                  label={t("passwordLabel")}
                  name="signup_password"
                  rules={[{ validator: validatePassword }]}
                >
                  <TextInput type="password" onChange={(e:any)=>handleFieldChange({signup_password:e.target.value})} placeholder={t("passwordLabel")} />
                </Form.Item>
                <Form.Item
                  label={t("confirmPasswordLabel")}
                  name="confirmPassword"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) =>
                        validateConfirmPassword(
                          _,
                          value,
                          getFieldValue("signup_password")
                        ),
                    }),
                  ]}
                >
                  <TextInput
                    type="password"
                    placeholder={t("confirmPasswordLabel")}
                  />
                </Form.Item>
                </> 
               }
                <CustomCheckbox
                  checked={isChecked}
                  onChange={(e: any) => setIsChecked(e.target.checked)}
                  className="mb-2"
                  label={t("iAcceptprivacyPolicyMsg")}
                ></CustomCheckbox>
              </>
            ) : (
              <>
              <div className="mb-2">
                <img src={`data:image/svg+xml;base64,${signUpPageInfo.captchaSvg}`}/>
              </div>
              <Form.Item rules={[{required:true,message:`${t("captchaNotMatchLabel")}`}]} name="captcha">
                <TextInput onChange={(e:any)=>handleFieldChange({captcha:e.target.value})}/>
              </Form.Item>
              </>
            )}
            <Form.Item>
              <PrimaryButton
                type="primary"
                className="w-100 mt-2"
                style={{ height: 40 }}
                htmlType="submit"
                disabled={!isChecked}
              >
                {t("signUpLabel")}
              </PrimaryButton>
            </Form.Item>
          </Form>
         {/* NEW DIV FOR THE TOGGLE */}
          <div className="text-center">
            <Link
              className="mx-1 text-nowrap"
              onClick={handleToggleSignupMethod}
                to="#" 
              style={{ fontWeight: 600 }}
            >
             {showSso ? t("signUpSsoLabel") : t("signUpEmailLabel")}

            </Link>
          </div>
          {/* NEW DIV FOR THE TOGGLE */}
          <div className="text-center">
            <span>{t("alreadyAccLabel")}</span>
            <Link
              className="mx-1 text-nowrap"
              to="/login"
              style={{ fontWeight: 600 }}
            >
              {t("signInLabel")}
            </Link>
          </div>
          <div className=" d-flex flex-column text-center pt-2">
            <span className="pt-2">{t("semusiPvtLabel")}</span>
            <Link className="pt-2" to="#">
              {t("privacyPolicyLinkLabel")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
