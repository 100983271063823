import { personalizationVariableInfoType } from "@customTypes/template";
import { Divider, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import {useSelector } from "react-redux";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
interface PersonalizationProps {
  personalizationState: { open: boolean; field: string };
  setPersonalizationState: (value: { open: boolean; field: string }) => void;
  setFormData: (data: any) => void;
}

const Personalization: FC<PersonalizationProps> = ({
  personalizationState,
  setPersonalizationState,
  setFormData,
}) => {
  const [form] = useForm();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [loading,setLoading]=useState(false)
  const [metaData, setMetaData] = useState<any>({});
  const [placeholder, setPlaceholder] = useState("");
  const [data, setData] = useState<personalizationVariableInfoType>({
    source: "user_info",
    variableLabel: undefined,
    defaultValue: undefined,
  });

  const fetchVariables = async () => {
    try {
      setLoading(true);
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        tableName:`app_users_${appId}`
      };
      //call api
      const {columns} = await centralApi(
        "GET",
        API_ENDPOINTS.GET_TRAIT_TABLE_INFO_URL,
        null,
        params
      );
      setMetaData((prevState: any) => ({ ...prevState, columns: columns}));
    } catch (error) {
    }
    setLoading(false);
  };
  //handle form change 
  const handleFormChange = (key: string, value: string) => {
    setData((prevState) => ({ ...prevState, [key]: value }));
  };
  //handle form submit
  const onSubmit = () => {
    if(personalizationState?.field){
      setFormData((prevState: any) => ({
        ...prevState,
        variableInfo: { ...prevState.variableInfo, [placeholder]: data },
        [personalizationState.field]:
        prevState[personalizationState.field] + placeholder,
      }));
    }
    setPersonalizationState({ open: false, field: "" });
  };
  useEffect(() => {
    if(personalizationState?.field){
      fetchVariables()
    }
    form.resetFields();
  }, [personalizationState]);
  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
            {t("saveLabel")}
          </PrimaryButton>
        </div>
      }
      open={personalizationState.open}
      placement="right"
      closable={false}
      onClose={() => setPersonalizationState({ open: false, field: "" })}
    >
      <div className=" mt-1 ">
        <h5 className="fw-semibold">{t("addVariableLabel")}</h5>
        <div className="mt-3">
          <Form
            colon={false}
            labelAlign="left"
            labelCol={{ flex: "110px" }}
            layout="horizontal"
            form={form}
            onFinish={onSubmit}
          >
            <FormItem
              name={"source"}
              rules={[{ required: true, message: `${t("selectSourceMsg")}` }]}
              label={t("selectSourceMsg")}
            >
              <Selector
                disabled={loading}
                loading={loading}
                showSearch
                onChange={(source: string) => {
                  handleFormChange("source", source)
                }}
                options={[{label:"User trait",value:"user_info"}]}
                placeholder={t("selectSourceMsg")}
              />
            </FormItem>
            <Divider />
              <FormItem className="" label={t("selectVariableMsg")} name={"variableLabel"}  rules={[{ required: true, message: `${t("selectVariableMsg")}` }]}>
                <Selector
                  loading={loading}
                  disabled={!data.source || loading}
                  onChange={(variable: string) => {
                    setPlaceholder(`{{${variable}}}`)
                    const label=metaData?.columns?.find(({value}:{value:string})=>value==variable)?.label
                    handleFormChange("variableLabel",label );
                  }}
                  showSearch
                  options={metaData?.columns?.filter(({jsonb}:{jsonb:string})=>jsonb && jsonb==data.source)}
                />
              </FormItem>
            <FormItem label={t("defaultValueLabel")} name="defaultValue"
            rules={[{ required: true, message: `${t("plzInputDefaultValueMsg")}` }]}
            >
              <TextInput
                onChange={(e: any) => {
                  handleFormChange("defaultValue", e.target.value);
                }}
                placeholder={t("defaultValueLabel")}
              />
            </FormItem>
          </Form>
        </div>
      </div>
    </CustomDrawer>
  );
};

export default Personalization;
//replace placeholders with placholder label
export const replacePersonlizedVariable = (
  text: string,
  variableInfo: { [key: string]: personalizationVariableInfoType } | undefined
) => {
  const string = text?.replace(/{{(.*?)}}/g, (match: any, key: any) => {
      return  variableInfo && variableInfo[`{{${key}}}`]? ` {{${variableInfo[`{{${key}}}`]?.variableLabel}:${
          variableInfo[`{{${key}}}`]?.defaultValue || ""
        }}}`:match
    
  });
    return string || "";
};
