"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageConfigTypeEnum = exports.QueueConfigTypeEnum = exports.ConfigTypeEnum = void 0;
// Enum to define the types of configurations available
var ConfigTypeEnum;
(function (ConfigTypeEnum) {
    ConfigTypeEnum["QUEUE"] = "QUEUE";
    ConfigTypeEnum["STORAGE"] = "STORAGE";
    ConfigTypeEnum["USERS"] = "users";
    ConfigTypeEnum["LOGS"] = "logs";
    ConfigTypeEnum["PROFILE"] = "profile";
    ConfigTypeEnum["TABLE_INFO"] = "tableinfo";
    ConfigTypeEnum["AUDIT_LOGS"] = "auditlogs";
    ConfigTypeEnum["MAPPING"] = "mapping";
})(ConfigTypeEnum || (exports.ConfigTypeEnum = ConfigTypeEnum = {}));
// Enum to define the types of queue configurations available
var QueueConfigTypeEnum;
(function (QueueConfigTypeEnum) {
    QueueConfigTypeEnum["PG"] = "PG";
    QueueConfigTypeEnum["AZURE"] = "AZURE";
})(QueueConfigTypeEnum || (exports.QueueConfigTypeEnum = QueueConfigTypeEnum = {}));
// Enum to define the types of storage configurations available
var StorageConfigTypeEnum;
(function (StorageConfigTypeEnum) {
    StorageConfigTypeEnum["MINIO"] = "MINIO";
    StorageConfigTypeEnum["AZURE"] = "AZURE";
})(StorageConfigTypeEnum || (exports.StorageConfigTypeEnum = StorageConfigTypeEnum = {}));
