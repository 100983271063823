import { useEffect, useState } from "react";
import { Layout } from "antd";
import HeaderNav from "../components/HeaderNav/HeaderNav";
import PanelSideNav from "../components/PanelSideNav/PanelSideNav";
import UserManagement from "../pages/UserManagement/UserManagement";
import { Content } from "antd/es/layout/layout";
import Campaign from "../pages/Campaign/Campaign";
import AppUsers from "../pages/AppUsers/AppUsers";
import { useDispatch, useSelector } from "react-redux";
import FunnelsTable from "../pages/Funnels/FunnelsTable/FunnelsTable";
import Journeys from "../pages/Journeys/JourneysTable/JourneysTable";
import CreateFunnels from "../pages/Funnels/CreateFunnel/CreateFunnels";
import JourneysCreate from "../pages/Journeys/JourneysCreate/JourneysCreate";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Login from "../pages/Auth/Login/Login";
import Verification from "../pages/Auth/Verification/Verification";
import AppDashboard from "../pages/Dashboard/AppDashboard/AppDashboard";
import Home from "../pages/Dashboard/Home/Home";
import ResetPassword from "../pages/Auth/ResetPassword/ResetPassword";
import ForgetPassword from "../pages/Auth/ForgetPassword/ForgetPassword";
import { EditProfile } from "../pages/EditProfile/EditProfile";
import DisplayPrefrence from "../pages/DisplayPrefrence/DisplayPrefrence";
import TransactionalCampaigns from "../pages/TransactionalCampaigns/TransactionalCampaigns";
import TransactionalCampaignsDetail from "../pages/TransactionalCampaigns/TransactionalCampaignsDetail";
import Exports from "../pages/Exports/Exports";
import AppSetting from "../pages/AppSetting/AppSetting";
import AcquisitionChurn from "../pages/AcquisitionChurn/AcquisitionChurn";
import Demographics from "../pages/Demographics/Demographics";
import UtmBuilder from "../pages/UtmBuilder/UtmBuilder";
import SetupNewApp from "../pages/SetupNewApp/SetupNewApp";
import Events from "../pages/Events/Events";
import {
  fetchLoginUser,
  logoutUser,
  setActiveAppUserRole,
} from "../redux/features/user/loginUserSlice";
import Usage from "../pages/Usage/Usage";
import Spinner from "../components/Spinner/Spinner";
import JourneysView from "../pages/Journeys/JourneysView/JourneysView";
import CampaignCreate from "../pages/Campaign/CampaignCreate/CampaignCreate";
import Trends from "../CDPpages/Trends/Trends";
import CampaignDetail from "../pages/Campaign/CampaignDetail/CampaignDetail";
import { WebPushReports } from "../pages/Campaign/WebPushReports/WebPushReports";
import DemoMembersTable from "../commonPages/demo/demoMembersTable/DemoMembersTable";
import Segments from "../commonPages/demo/segments/Segments";
import { DemoDashboard } from "../commonPages/demo/demoDashboard/DemoDashboard";
import TierList from "../commonPages/demo/tier/TierList";
import CreateTier from "../commonPages/demo/tier/CreateTier";
import RewardList from "../commonPages/demo/rewards/RewardList";
import CreateReward from "../commonPages/demo/rewards/CreateReward";
import Template from "../pages/Campaign/Templates/Template";
import { heartBeat } from "services/heartBeat";
import { STATUS } from "redux/constant";
import AudienceTable from "pages/Audience/AudienceTable/AudienceTable";
import AudienceCreate from "pages/Audience/AudienceCreate/AudienceCreate";
import TraitsTable from "pages/Audience/Traits/TraitsTable/TraitsTable";
import CreateTrait from "pages/Audience/Traits/CreateTrait/CreateTrait";
import DemoSync from "pages/Audience/AudienceCreate/DemoSync";
import PushGenAi from "commonPages/demo/genAi/PushGenAi/PushGenAi";
import Dashboard from "commonPages/demo/genAi/Dashboard/Dashboard";
import NotFound from "pages/NotFound/NotFound";
import FunnelDetails from "pages/Funnels/FunnelDetails/FunnelDetails";
import { ROUTES } from "utils/constants";
import Customer360 from "pages/Customer360/Customer360";
import AppUsersDetails from "pages/AppUsers/AppUsersDetails/AppUsersDetails";
import DataTransformation from "pages/DataTransformation/DataTransformation";
import Models from "pages/Audience/Models/Models";
import UserListNav from "pages/UserListManagement/UserListNav";
import { UserRoleEnum } from "utils/enums";
import { CONSTANTS, FEATURE_FLAGS_CONFIG, roleCheckTypes } from "utils/constants/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import JourneyManagement from "pages/DataManagement/JourneyManagement/JourneyManagement";
import AuditLogs from "pages/AuditLogs/AuditLogs";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import Customer360List from "pages/Customer360/Customer360List/Customer360List";
import Personalisation from "pages/DataManagement/Personalisation/Personalisation";

function Panel() {
  const [showSideNav, setShowSideNav] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { status } = useSelector((state: any) => state.loginUser);
  const userRole = loginUser?.activeAppUserRole;
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const location = useLocation();
  const { data } = useSelector((state: any) => state.featuresSetting);
  const featuresSetting = data[0]?.features;
  const isNotProtectedRoute = () => {
    return [
      ROUTES.SIGNUP,
      ROUTES.LOGIN,
      ROUTES.RESET,
      ROUTES.FORGET,
      ROUTES.AUTHENTICATION,
    ].includes(location.pathname);
  };
  useEffect(() => {
    //if signup or login page then the side nav will be invisible
    if (isNotProtectedRoute()) {
      setShowSideNav(false);
    } else {
      setShowSideNav(true);
    }
  }, [location.pathname , loginUser]);
  useEffect(() => {
    //fetch loggedIn user details  if the route is protected
    if (!isNotProtectedRoute()) {
      dispatch(fetchLoginUser());
    }
  }, []);
  useEffect(() => {
    if (status == STATUS.ERROR && location.pathname != ROUTES.AUTHENTICATION) {
      dispatch(logoutUser());
    }
  }, [status]);
  useEffect(() => {
    if (status == STATUS.LOGGEDOUT) {
      navigate(ROUTES.LOGIN);
    }
  }, [status]);
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    //invoke this function whenever the visibility changes
    const handleVisibilityChange = () => {
      //check if the screen is visible
      if (document.visibilityState === "visible") {
        updateUser();
        startInterval();
      } else {
        //clean up interval if screen is invisible
        clearInterval(intervalId);
      }
    };
    const updateUser = async () => {
      try {
        //update the user only if the user is loggedIn
        if (
          loginUser.api_key &&
          status !== STATUS.ERROR &&
          status !== STATUS.LOGGEDOUT
        ) {
          await heartBeat(loginUser.api_key);
        }
      } catch (error) {
        //if any error occured logout the user and cleanup interval
        dispatch(logoutUser());
        clearInterval(intervalId);
      }
    };
    const startInterval = () => {
      //start interval only when the user is loggedIn
      if (
        loginUser.api_key &&
        status !== STATUS.ERROR &&
        status !== STATUS.LOGGEDOUT
      ) {
        intervalId = setInterval(updateUser, 40000);
      }
    };
    // Start the interval if conditions are met initially
    startInterval();
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Clean up the event listener and interval on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [loginUser]);
  useEffect(() => {
    if(! isNotProtectedRoute() && loginUser.api_key){
      dispatch(getToken())
    }
  }, [loginUser ,location.pathname ]);
  //calls this use effect when user login the panel or appid changes in activeappid
  useEffect(() => {
    if (appId && loginUser.api_key) {
      dispatch(setActiveAppUserRole(appId));
    }
  }, [appId, loginUser]);

  const middleWare = (component: any, route = { name: "", url: "" }) => {
    const routeName = route.name;
    if (status === STATUS.LOADING) {
      return <Spinner />;
    }
    // if user is not valid ( case of tampered api response )
    if( !loginUser.isValid){
      return <NotFound setShowSideNav={setShowSideNav} />;
    }
    const isAuthorized =
      userRole === UserRoleEnum.GLOBAL_ADMIN ||
      userRole === UserRoleEnum.APP_ADMIN;
    const isFeatureDisabled = featuresSetting?.[routeName] ?? false; //check from appSetting is it enable or disabled

    if (status === STATUS.IDLE && loginUser.api_key) {
      // Redirect to the dashboard if appId is null and not on the dashboard route
      if (appId === null && route.url !== ROUTES.DASHBOARD) {
        navigate(ROUTES.DASHBOARD);
      }
    // Define the routes that require authorization
    const authorizedRoutes = [ROUTES.DASHBOARD_APPSETTING];

      // Render NotFound if the feature is disabled or user is not authorized for specific routes
      if ( isFeatureDisabled || (!isAuthorized && authorizedRoutes.includes(route.url))) {
        return <NotFound setShowSideNav={setShowSideNav} />;
      }

      // Render the component if feature is not disabled
      return component;
    }

    return null; // Default case if none of the conditions match
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <HeaderNav></HeaderNav>
      <Layout
        className="site-layout"
        style={{ height: "100%", overflow: "hidden" }}
      >
        {showSideNav && <PanelSideNav />}

        <Content
          style={{ padding: 0, overflowY: "auto", overflowX: "hidden" }}
          className="px-sm-3 p-1 py-2"
        >
          <Routes>
            <Route
              path={ROUTES.DASHBOARD}
              element={middleWare(<Home />, FEATURE_FLAGS_CONFIG.HOME)}
            />
            <Route
              path={ROUTES.DASHBOARD_APP}
              element={middleWare(<AppDashboard />)}
            />

            <Route
              path={ROUTES.DASHBOARD_ALLUSERS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.UserAdminRights]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>  
                 <UserManagement />
                </AccessControl>
                }
            />
            <Route path={ROUTES.SIGNUP} element={<SignUp />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.RESET} element={<ResetPassword />} />
            <Route path={ROUTES.FORGET} element={<ForgetPassword />} />

            <Route path={ROUTES.AUTHENTICATION} element={<Verification />} />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN}
              element={
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor , roleCheckTypes.Reviewer ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
              <Campaign />
            </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_DETAIL}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CampaignDetail />
              </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_REVIEW}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor , roleCheckTypes.Reviewer ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CampaignDetail mode={CONSTANTS.REVIEW}  />
              </AccessControl>
            }
            />

            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CampaignCreate />
               </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_APPUSER}
              element={middleWare(<AppUsers />)}
            />
            <Route
              path={ROUTES.DASHBOARD_FUNNELS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                <FunnelsTable />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_FUNNEL_DETAIL}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
                <FunnelDetails />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_FUNNELS_CREATE}
              element={
               <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}> 
               <CreateFunnels />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_JOURNEYS}
              element={middleWare(<Journeys />)}
            />

            <Route
              path={ROUTES.DASHBOARD_USER_LIST}
              element={middleWare(<UserListNav />)}
            />

            <Route
              path={ROUTES.DASHBOARD_JOURNEYS_CREATE}
              element={middleWare(<JourneysCreate />)}
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <AudienceTable />
              </AccessControl>
               }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                 <AudienceCreate />
              </AccessControl>
            
            }
            />
            <Route
              path={ROUTES.DASHBOARD_ACQUISITION}
              element={middleWare(<AcquisitionChurn churn={false} />)}
            />
            <Route
              path={ROUTES.DASHBOARD_CHURN}
              element={middleWare(
                <AcquisitionChurn churn={true} />,
                FEATURE_FLAGS_CONFIG.CHURN
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_DEMOGRAPHICS}
              element={middleWare(
                <Demographics />,
                FEATURE_FLAGS_CONFIG.DEMOGRAPHICS
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_UTMBUILDER}
              element={middleWare(<UtmBuilder />)}
            />
            <Route
              path={ROUTES.DASHBOARD_CREATEAPP}
              element={middleWare(<SetupNewApp />)}
            />
            <Route
              path={ROUTES.DASHBOARD_EDIT_PROFILE}
              element={middleWare(<EditProfile />)}
            />
            <Route
              path={ROUTES.DASHBOARD_DISPLAY_PREFRENCE}
              element={middleWare(<DisplayPrefrence />)}
            />

            <Route
              path={ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN}
              element={middleWare(<TransactionalCampaigns />,FEATURE_FLAGS_CONFIG.TRANSACTIONAL_CAMP)}
            />
            <Route
              path={ROUTES.DASHBOARD_TRANSACTIONALCAMPAIGN_DETAIL}
              element={middleWare(<TransactionalCampaignsDetail />)}
            />
            <Route
              path={ROUTES.DASHBOARD_APPSETTING}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.SuperUser ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <AppSetting />
              </AccessControl>             
            }
            />
             <Route
              path={ROUTES.DASHBOARD_AUDIT_LOGS}
              element={
                <AccessControl accessControl={{role:[roleCheckTypes.Infosec ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <AuditLogs />
                </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_EXPORTS}
              element={middleWare(<Exports />)}
            />
            <Route
              path={ROUTES.DASHBOARD_EVENTS}
              element={middleWare(<Events />)}
            />
            <Route
              path={ROUTES.DASHBOARD_USAGE}
              element={middleWare(<Usage />)}
            />
            <Route
              path={ROUTES.DASHBOARD_JOURNEYS_VIEW}
              element={middleWare(<JourneysView />)}
            />
            <Route
              path={ROUTES.DASHBOARD_TRENDS_ALLTRENDS}
              element={<Trends />}
            />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_WEBPUSHREPORTS}
              element={<WebPushReports />}
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <TraitsTable />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
                <CreateTrait />
              </AccessControl>  
              }
            />
            <Route
              path={ROUTES.DASHBOARD_CAMPAIGN_TEMPLATE}
              element={
              <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}} fallbackComponent={<NotFound setShowSideNav={setShowSideNav}/>}>
               <Template />
              </AccessControl>
            }
            />
            <Route
              path={ROUTES.DASHBOARD_APPUSER_DETAIL}
              element={middleWare(<Customer360 />)}
            />
            <Route
              path={ROUTES.DASHBOARD_APPUSERS_DETAILS}
              element={middleWare(<AppUsersDetails />)}
            />
            <Route
              path={ROUTES.NOT_FOUND}
              element={<NotFound setShowSideNav={setShowSideNav} />}
            />
            <Route
              path={ROUTES.DASHBOARD_DATA_TRANSFORMATION}
              element={middleWare(<DataTransformation />)}
            />

             <Route
              path={ROUTES.DASHBOARD_JOURNEY_MANAGEMENT}
              element={middleWare(<JourneyManagement />)}
            />

            <Route
              path={ROUTES.DASHBOARD_CUSTOMER_DETAIL}
              element={
               <AccessControl>
                 <Customer360 />
               </AccessControl> 
                }
            />
            <Route
              path={ROUTES.DASHBOARD_CUSTOMER}
              element={
                <AccessControl>
                <Customer360List/>
                </AccessControl>
              }
            />
            <Route
              path={ROUTES.DASHBOARD_MANAGEMENT_PERSONALISATION}
              element={middleWare(<Personalisation />)}
            />
            {/* demo pages */}
            <Route
              path={ROUTES.DASHBOARD_LOYALTY}
              element={middleWare(
                <DemoDashboard />,
                FEATURE_FLAGS_CONFIG.LOYALTY
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_MEMBERS}
              element={
                <DemoMembersTable
                  onTabClickUrl={ROUTES.DASHBOARD_LOYALTY_C360}
                />
              }
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_SEGMENTS}
              element={middleWare(<Segments />, FEATURE_FLAGS_CONFIG.LOYALTY)}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_TIER}
              element={middleWare(<TierList />, FEATURE_FLAGS_CONFIG.LOYALTY)}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_TIER_CREATE}
              element={middleWare(<CreateTier />, FEATURE_FLAGS_CONFIG.LOYALTY)}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_C360}
              element={<Customer360 />}
            />
         
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_SYNC}
              element={<DemoSync />}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_REWARDS}
              element={middleWare(<RewardList />, FEATURE_FLAGS_CONFIG.LOYALTY)}
            />
            <Route
              path={ROUTES.DASHBOARD_LOYALTY_REWARDS_CREATE}
              element={middleWare(
                <CreateReward />,
                FEATURE_FLAGS_CONFIG.LOYALTY
              )}
            />
            <Route path={ROUTES.DASHBOARD_PUSH_GENAI} element={<PushGenAi />} />
            <Route path={ROUTES.GENAI_DASHBOARD} element={<Dashboard />} />
            <Route
              path={ROUTES.DASHBOARD_AUDIENCE_MODELS}
              element={<Models />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Panel;
