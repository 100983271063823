export async function getFileDimensions(file: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = file
    img.onload = () => {
      const dimensions = { width: img.width, height: img.height }
      resolve(dimensions)
    }

    img.onerror = (error) => {
      reject(error)
    }
  })
}
export const getVideoFileSize = async (file:any) => {
  return file.size;
}
export const validateImageDimensions = async (
  type:
    | "pushExpanded"
    | "pushHeader"
    | "webPushExpanded"
    | "webPushHeader"
    | "inAppNotificationFull"
    | "inAppNotificationMini"
    | "inAppNotificationSmall"
    | "inAppNotificationHeader"
    | "webPopUp"
    |"imageWhatsApp"
    |"inAppNotificationHeaderFooterExpanded",
  file: any
) => {
  const requiredDimension = {
    pushExpanded: {
      width: { from: 600, to: 800 },
      height: { from: 300, to: 400 },
    },
    pushHeader: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    webPushExpanded: {
      width: { from: 600, to: 800 },
      height: { from: 300, to: 400 },
    },
    webPushHeader: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    inAppNotificationFull: {
      width: { from: 400, to: 800 },
      height: { from: 768, to: 1024 },
    },
    inAppNotificationMini: {
      width: { from: 400, to: 400 },
      height: { from: 400, to: 400 },
    },
    inAppNotificationSmall: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    inAppNotificationHeader: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    webPopUp: {
      width: { from: 200, to: 300 },
      height: { from: 100, to: 200 },
    },
    imageWhatsApp: {
      width: { from: 600, to: 800 },
      height: { from: 300, to: 400 },
    },
    inAppNotificationHeaderFooterExpanded: {
      width: { from: 800, to: 800 },
      height: { from: 200, to: 200 },
    },
    
  }

  // const videoSize = await getVideoFileSize(file);

  const imageDimensions: any = await getFileDimensions(
    URL.createObjectURL(file)
  )
  if (
    imageDimensions.width >= requiredDimension[`${type}`].width.from &&
    imageDimensions.width <= requiredDimension[`${type}`].width.to &&
    imageDimensions.height >= requiredDimension[`${type}`].height.from &&
    imageDimensions.height <= requiredDimension[`${type}`].width.to
  ) {
    return { valid: true, message: "" }
  } else {
    return {
      valid: false,
      message: `Image dimensions need to be in between (w)${
        requiredDimension[`${type}`].width.from
      } x ${requiredDimension[`${type}`].height.from}(h) and (w)${
        requiredDimension[`${type}`].width.to
      } x ${requiredDimension[`${type}`].height.to}(h)`,
    }
  }
}
