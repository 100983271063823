import React, { useState } from "react";
import { Dropdown, MenuProps } from "antd";
import "./FilterPopup.css";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Search from "../../Inputs/Search/Search";
type FilterPopupSubTabType = {
  id: string;
  idType: "static" | "dynamic";
  title: string;
  icon: string; // Assuming icons are strings representing icon names
};
type FilterPopupTabType = {
  id: number;
  icon: string; // Assuming icons are strings representing icon names
  title: string;
  description: string;
  subtabs: FilterPopupSubTabType[];
};

type propType = {
  data: FilterPopupTabType[];
  onItemClick: (data: { key: string }) => void;
  children: React.ReactElement;
};
function FilterPopup({ onItemClick, children, data=[] }: propType) {
  const [search, setSearch] = useState("");

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomLeft"
      overlay={
        <Tabs className="d-flex p-1 custom-filter-dropdown-tab">
          <TabList className="p-0 w-50 my-0 custom-filter-left-list">
            {data.map((tab, index) => (
              <Tab
                className=" mx-0 mb-1 custom-filter-tab text-overflow-ellipsis"
                key={tab.id}
                onClick={() => {
                  setSearch("");
                }}
              >
                <span className="d-flex m-2 cursor-pointer align-items-center">
                  <img
                    width={35}
                    height={35}
                    src={tab.icon}
                    alt={`${index}audiencePartIcon`}
                  ></img>{" "}
                  <div className="d-flex flex-column">
                    <span className="mx-1 custom-filter-tab-title">
                      {tab.title}
                    </span>
                    <span className="mx-1 text-overflow-ellipsis">
                      {tab.description}
                    </span>
                  </div>
                </span>
              </Tab>
            ))}
          </TabList>

          {data.map((tab, index) => (
            <TabPanel  key={tab.id} className={"custom-filter-tab-panel"}>
              <Search
                className="mx-1 w-100 custom-filter-search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <TabList className="py-1 px-1 custom-filter-right-list w-100">
                {tab?.subtabs?.map((subtab) =>
                   subtab?.title?.toLowerCase().includes(search?.toLowerCase()) ? (
                    <Tab
                      className="custom-filter-tab mx-0 text-overflow-ellipsis"
                      key={subtab.id}
                      onClick={() => onItemClick({ key: subtab.id })}
                    >
                      <span className="d-flex m-2 align-items-center cursor-pointer">
                        {subtab.icon ? (
                          <img
                            width={26}
                            height={26}
                            src={subtab.icon}
                            alt={`audiencePartIcon${index}`}
                          ></img>
                        ) : (
                          ""
                        )}
                        <span className="mx-1">{subtab.title}</span>
                      </span>
                    </Tab>
                  ) : (
                    <></>
                  )
                )}
              </TabList>
            </TabPanel>
          ))}
        </Tabs>
      }
    >
      {children}
    </Dropdown>
  );
}
export default FilterPopup;
