import { FC } from "react";
import "./Customer360Card.css";
import { CONSTANTS } from "utils/constants/constants";

interface HeaderCardProps {
  firstKey: string;
  secondKey: string;
  thirdKey: string;
  firstValue: string;
  secondValue: string;
  thirdValue: string;
  cardType: typeof CONSTANTS.HEADER;
}

interface DetailsCardProps {
  cardType: typeof CONSTANTS.CARDS;
  cardFilteredDetails: any;
}

interface CardTransactions {
  cardType: typeof CONSTANTS.OFFER;
  filteredCardTransactions : any;
}

const Customer360Card: FC<HeaderCardProps | DetailsCardProps | CardTransactions> = ({ ...data }) => {
  return data.cardType === CONSTANTS.HEADER ? (
    <div className="py-2 d-flex flex-column gap-3 justify-content-center c-three-sixty-header-card p-3">
      <div className="d-flex justify-content-between gap-3">
        <span>{data.firstKey}</span>
        <span>{data.firstValue}</span>
      </div>
      <div className="d-flex justify-content-between gap-3">
        <span>{data.secondKey}</span>
        <span>{data.secondValue}</span>
      </div>
      <div className="d-flex justify-content-between gap-3">
        <span>{data.thirdKey}</span>
        <span>{data.thirdValue}</span>
      </div>
    </div>
  ) : data.cardType === CONSTANTS.CARDS ? (
    <div className="d-flex ">
    {data.cardFilteredDetails
      ?.filter((card: any) => card.length > 0) // Filter out empty arrays
      .map((card: any, index: number) => (
        <div key={index} className="p-4 d-flex flex-column gap-2 c-three-sixty-card-details me-2">
          {card.map((prop: any) => (
            <div key={prop.label} className="d-flex justify-content-between gap-3">
              <span>{prop.label}</span>
              <span>{prop.value?.toString()}</span>
            </div>
          ))}
        </div>
      ))}
  </div>
  ) :  data.cardType === CONSTANTS.OFFER ? (
    <div className="c-three-sixty-transaction-card-scrollable-container">
  {data.filteredCardTransactions?.map((chunks: any, index: any) => (
    <div key={index} className="d-flex flex-column">
      {chunks.map((chunk: any, chunkIndex: any) => (
        <div key={chunkIndex} className="p-4 d-flex flex-column gap-2 c-three-sixty-transaction-card mb-2">
          {/* Transaction Code outside the card, styled as heading */}
          {chunk.map((prop: any, idx: number) => (
            prop.label === "Transaction Code" && (
              <div key={idx} className="transaction-code-container mb-3">
                <span className="transaction-code-text">
                  {prop.label}: {prop.value}
                </span>
              </div>
            )
          ))}

          {/* Rest of the card content */}
          {chunk.map((prop: any, idx: number) => (
            prop.label !== "Transaction Code" && (
              <div key={idx} className="d-flex justify-content-between gap-3">
                <span>{prop.label}</span>
                <span>{prop.value}</span>
              </div>
            )
          ))}
        </div>
      ))}
    </div>
  ))}
</div>

    
  ) : (
    <></>
  );
};

export default Customer360Card;
